@use 'sass:map';
@use '../settings/typo-setting' as typography;

$spacing-x: 0.75rem !default;
$spacing-y: 0 !default;
$gap: var(--global-gap-small) !default;
$outline-border-width: 2px !default;
$easing: var(--global-easing-time) var(--global-easing) !default;
$sizes: (
  small: 1.5rem,
  medium: 2rem,
);
$font-sizes: (
  small: .8125rem,
  medium: .9375rem,
);
$icon-sizes: (
  small: .7125rem,
  medium: .8375rem,
);
$radius: (
  small: var(--global-radius-small),
  medium: var(--global-radius),
);

@mixin base {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $gap;
  flex: 0 0 auto;
  margin: 0;
  font-weight: typography.$font-weight-medium;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color $easing, color $easing, border-color $easing;
  pointer-events: auto;
  box-sizing: border-box;

  &:disabled,
  &.is-disabled {
    pointer-events: none;
  }
}

@mixin size($size) {
  @if map.has-key($sizes, $size) {
    height: map.get($sizes, $size);

    &:not(.itera-button--icon-only) {
      padding: $spacing-y $spacing-x;
    }

    &:not(.itera-button--icon-only, [data-block="true"]) {
      min-width: map.get($sizes, $size);
      width: auto;
    }

    &.itera-button--icon-only {
      width: map.get($sizes, $size);
      padding: 0;
    }

    @if map.has-key($radius, $size) {
      font-size: map.get($font-sizes, #{$size});

      > svg-icon {
        font-size: map.get($icon-sizes, #{$size}) !important;
      }
    } @else {
      @error 'Font sizes map button.$font-sizes does not contain #{$size} size.';
    }

    @if map.has-key($font-sizes, $size) {
      border-radius: map.get($radius, #{$size});
    } @else {
      @error 'Radius map button.$radius does not contain #{$size} size.';
    }
  } @else {
    @error 'Fuck, your button size #{$size} is not included in $sizes map.';
  }
}

@mixin theme($bg, $text, $border: transparent) {
  background: $bg;
  color: $text;
  border-color: $border;
}
