@use '../tools/breakpoint.mixin' as bp;

.cards-grid {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include bp.breakpoint('medium') {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

    &.card-limit {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include bp.breakpoint('large') {
    grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));

    &.card-limit {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
