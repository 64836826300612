@use '../settings/typo-setting' as typography;

label, .form-field-label {
  font-size: typography.$font-size-mono;
  font-weight: typography.$font-weight-medium;
  color: var(--tones-main-primary-800);

  &.inline {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    cursor: pointer;
  }

  &.mat-radio-label {
    margin-bottom: 0;
  }
}
