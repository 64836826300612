:root {
  --color-border: var(--tones-main-primary-400);
  --color-border-100: var(--tones-main-primary-100);
  --color-border-200: var(--tones-main-primary-200);
  --color-border-hover: var(--tones-main-primary-800);
  --color-border-disabled: var(--tones-main-primary-50);
  --color-border-readonly: var(--tones-main-primary-50);

  --kaizen-core-background-main: var(--tones-main-primary-25);
  --color-background-disabled: var(--tones-main-primary-25);

  --color-placeholder: var(--tones-main-primary-400);
  --color-label: var(--tones-main-primary-800);

  --color-terminal-background: var(--palette-static-black);

  --color-orange: var(--tones-semantic-notice-500);
  --color-orange-red: #ff6535;

  --color-text: var(--palette-static-black);

  --color-light: var(--palette-static-white);
  --color-dark: var(--palette-static-black);
}
