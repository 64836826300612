@use 'sass:map';
@use 'button';
@use '../settings/typo-setting' as typography;

.tabs-bar {
  position: relative;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  background-color: var(--palette-static-white);

  &--transparent {
    background-color: transparent;
  }

  &--primary-75 {
    background-color: var(--tones-main-primary-75);
  }

  a.tab-button {
    padding: .5rem .75rem;
    text-decoration: none;
  }

  .tab-button {
    display: flex;
    align-items: center;
    border-radius: 0;
    padding: .5rem 0 .5rem .75rem;
    border-bottom: 2px solid transparent;
    font-size: map.get(typography.$font-sizes, caption);
    cursor: pointer;

    &.is-active {
      border-color: var(--tones-main-accent-500);
      background-color: transparent;
    }

    &.is-active-white {
      background-color: var(--palette-static-white);
    }

    .mat-checkbox .mat-checkbox-layout {
      margin-bottom: 0;
    }

    &--rounded {
      border-radius: 4px;
      border: none;
      padding: .25rem 1rem;
    }

    > button {
      @include button.base;
      border: none;
      border-radius: 0;
      background: transparent;

      &:hover {
        color: inherit;
      }
    }
  }

  button.tab-button {
    border-width: 0 0 2px 0;
    background: transparent;
  }
}
