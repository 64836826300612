@use 'sass:list';

$text-colors: () !default;
$text-colors: list.join((
  primary,
  primary-100,
  primary-200,
  primary-300,
  primary-400,
  primary-500,
  accent,
  negative,
  notice,
  positive,
  informative,
  light,
  dark
), $text-colors);


.text-color {
  @each $color in $text-colors {
    &--#{$color} {
      color: var(--color-#{$color});
    }
  }
}
