.chart {
  width: 100%;
  margin: 30px 0;
  min-height: 600px;
  max-height: 1200px;

  h2 {
    margin: 30px 0;
  }
}

.tooltip-series {
  white-space: pre-line;
  max-width: 500px;
}

.type-tooltip {
  white-space: pre-wrap;
}

.chart-legend .legend-title {
  margin-bottom: 8px !important;
}

.chart-legend .legend-labels {
  background: none !important;
}

.chart-legend .legend-label {
  margin: 8px 8px 12px !important;
  color: var(--tones-main-primary-800) !important;
}

.chart-legend .legend-label-color {
  border-radius: 20px !important;
  height: 12px !important;
  margin-right: 10px !important;
  width: 12px !important;
}

.chart-legend .legend-label-text {
  font-size: 14px !important;
}

.legend-label-text {
  white-space: pre-line !important;
}

.chart-tooltip {
  padding: 20px 10px;
}

.granularity-selector {
  display: flex;
  gap: .5rem;
}

.selector-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
