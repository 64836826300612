.list {
  display: block;
  padding-bottom: 2px;
  text-decoration: underline;
}

.list-item {
  font-size: 13px;
  line-height: 15px;
}
