@use '../settings/typo-setting' as typography;

.health {
  font-weight: typography.$font-weight-medium;
  font-size: typography.$font-size-sm;

  &.healthy {
    color: var(--tones-semantic-positive-600);
  }

  &.warning {
    color: var(--tones-semantic-notice-600);
  }

  &.unhealthy {
    color: var(--tones-semantic-negative-500);
  }
}
