/// Sets the element as oneline with ellipsis
/// @group common/utils
/// @param {Keyword} $display - can be inline, inline-block, block or undefined
/// If it is undefined, no display option is included.
/// It is because of Kendo library and managing some styling in Kendo components.
@mixin ellipsis($display: block) {
  @if $display != undefined {
    @if $display == block or $display == inline-block or $display == inline {
      display: $display;
    } @else {
      display: block;
    }
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-ellipsis {
  @include ellipsis();
}
