@use '../settings/breakpoint-setting' as bp-setting;
////
/// @group breakpoint
////

/// Helper function to get next breakpoint
///
/// @param {Keyword} $key Breakpoint name from $breakpoints
/// @param {Map} $map [$breakpoints] Breakpoint map transformed to the list
@function next-bp($key, $map: bp-setting.$breakpoints) {
  // Make a list of keys from map
  $values: map-keys($map);

  $i: 0;

  // If the key exists, get the index of the key within the map and add 1 to it for the next breakpoint in the map
  @if (map-has-key($map, $key)) {
    $i: index($values, $key) + 1;
  }

  // If the key doesn't exist, or it's the last key in the map, return null
  @if ($i > length($map) or $i == 0) {
    @return null;
  } @else {
    @return map-get($map, nth($values, $i));
  }
}

/// Media queries including
@mixin breakpoint($query, $orientation: null) {
  $min-query-value: null;
  $max-query-value: null;

  // Check the breakpoint name contains 'only' substring.
  @if str-index(#{$query}, 'only') != null {
    // Find the only position in string minus two letters to get maximal position of breakpoint name
    $query-name-index: str-index($string: #{$query}, $substring: 'only') - 2;
    // when it contains only, find the breakpoint name
    $query-name: str-slice(#{$query}, 1, $query-name-index);
    // Find the value of breakpoint
    $min-query-value: map-get(bp-setting.$breakpoints, #{$query-name});
    // Check if the next breakpoint exists and set its value for 'only' range
    $max-query-value: next-bp(#{$query-name});
  } @else if str-index($query, 'only') == null {
    // Substring 'only' does not exist, set the min width only
    $min-query-value: map-get(bp-setting.$breakpoints, #{$query});
  }

  // Prepare string for @media
  // Include orientation when it is not null only
  $orientation-value: if($orientation == null, '', 'and (orientation: #{$orientation})');
  // Include min-width every time
  $min-width-value: 'and (min-width: #{$min-query-value})';
  // Include max-width when it is not null only
  $max-width-value: if($max-query-value == null, '', 'and (max-width: #{$max-query-value})');
  // Manage @media string in one place
  $media-query-string: screen #{$orientation-value} #{$min-width-value} #{$max-width-value};

  // Output of the mixin: @media query
  @media #{$media-query-string} {
    @content;
  }
}
