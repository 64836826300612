@use '../settings/typo-setting' as typography;

.editor {
  color: var(--palette-static-white);
  background-color: var(--alpha-static-black-90);
  border-radius: var(--global-radius);
  border: none;
  font-family: typography.$font-family-monospace;
  font-size: typography.$font-size-mono;
  line-height: 2em;
}
