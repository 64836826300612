@use '../settings/typo-setting' as typography;
@use '../tools/input.mixin' as input;

input:not(.input--inner, [type='checkbox'], [type='radio']),
textarea,
.input-unit {
  @include input.base;
  @include input.theme;
  @include input.typography;

  &.no-border {
    border: none;
  }
}

input:not(.input--inner),
textarea {
  @include input.focus;
}

input,
.input-unit {
  &.small {
    height: 24px;
    padding: 6px 8px;
  }
}

textarea {
  $line-height: 1.25rem;
  height: auto;
  line-height: #{$line-height};
  min-height: calc(
    #{$line-height} + (#{input.$spacing-y} * 2) + #{input.$border-width} + #{input.$border-bottom-width}
  );
}

.input-unit {
  @include input.focus($focus-within: true);
  gap: 0.5rem;
  padding: 0;
  overflow: hidden;

  .input--inner {
    @include input.typography;
    height: 100%;
    padding: input.$spacing;
    border: none;
    border-radius: 0;
    background: transparent;
  }
}

.input-suffix {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin-left: -2.75rem;
  cursor: pointer;
}

// Input utils

.input--width {
  &--130 {
    width: 130px;
  }

  &--72 {
    width: 72px;
  }

  &--auto {
    width: auto;
  }

  &--date-time {
    width: 12.5rem;
  }
}

.input--max-width {
  &--130 {
    max-width: 130px;
  }

  &--190 {
    max-width: 190px;
  }
}

.input-negative-margin {
  margin-left: -0.75rem;
}
