:root {
  --border-radius: 5px;
  --global-radius: 8px;
  --global-radius-big: 16px;
  --global-radius-small: 4px;
  --global-gap-xsmall: 0.25rem;
  --global-gap-small: 0.5rem;
  --global-gap: 1rem;
  --global-gap-medium: 1.5rem;
  --global-gap-large: 2rem;
  --global-easing-time: 300ms;
  --global-easing: ease;
}
