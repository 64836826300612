@use 'sass:map';
@use '../tools/breakpoint.mixin' as bp;
@use '../settings/breakpoint-setting' as bpset;

$directions: (
  top,
  right,
  bottom,
  left
);

@mixin generate-spacing($option) {
  @for $i from 1 to 15 {
    $a: $i * 8;

    .#{$option}-#{$a} {
      #{$option}: $a * 1px !important;
    }

    .#{$option}-vertical-#{$a} {
      #{$option}: ($a * 1px) 0 !important;
    }

    .#{$option}-horizontal-#{$a} {
      #{$option}: 0 ($a * 1px) !important;
    }

    .no-#{$option} {
      #{$option}: 0 !important;
    }

    @each $direction in $directions {
      .#{$option}-#{$direction}-#{$a} {
        #{$option}-#{$direction}: $a * 1px !important;
      }

      .no-#{$option}-#{$direction} {
        #{$option}-#{$direction}: 0 !important;
      }

      @each $bp, $bpvalue in bpset.$breakpoints {
        @include bp.breakpoint($bp) {
          .#{$option}-#{$direction}-#{$a}--#{$bp} {
            #{$option}-#{$direction}: $a * 1px !important;
          }

          .no-#{$option}-#{$direction}--#{$bp} {
            #{$option}-#{$direction}: 0 !important;
          }
        }
      }
    }
  }
}

@include generate-spacing('margin');
@include generate-spacing('padding');
