@use '../tools/media-breakpoints' as bp;
@use '../settings/typo-setting' as typography;

.mat-select {
  height: 32px;
  background: var(--palette-static-white);
  border-radius: 5px;
  text-align: left;
  box-sizing: border-box;
  box-shadow: var(--shadow-default-3);

  &.select--full-width {
    width: 100% !important;
  }

  &.select--width {
    &--130 {
      width: 130px;
    }
  }

  &.select--max-width {
    &--130 {
      max-width: 130px;
    }
  }

  .mat-select-trigger {
    height: 100%;
    padding: 0 .5rem;

    .mat-select-value {
      vertical-align: middle;
    }
  }

  &.mat-select-disabled {
    opacity: .8;
    background-color: var(--tones-main-primary-100);
  }

  &.ng-invalid.ng-touched {
    border-color: var(--tones-semantic-negative-500);
    background-color: var(--alpha-semantic-negative-10);
  }

  @include bp.screen('l') {
    width: 110px !important;
  }
}

.mat-select-value-text,
.mat-select-placeholder {
  font-size: typography.$font-size
}

.mat-select-panel {
  border-radius: var(--global-radius) !important;
  font-size: typography.$font-size !important;
}

// options height
.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  height: 2.7rem !important;
  line-height: 2.7rem !important;
}

// MAT-MENU
.menu-trigger {
  width: 130px;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  background-color: var(--palette-static-white);
  font-size: 14px;
  color: var(--tones-main-primary-500);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  outline: none;

  i {
    font-size: 11px;
  }
}
