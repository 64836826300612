// @ToDo (radim): all this should be replaced by new UI kit component, rename it to group and change input-group with label (remove)
@use 'sass:map';
@use '../settings/typo-setting' as typography;

.input-block {
  display: flex;

  .input-block__prefix,
  .input-block__suffix {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 .5rem;
    min-width: 32px;
    height: 2rem;
    border: 2px solid var(--color-border-200);
    background-color: var(--tones-main-primary-50);
    font-size: map.get(typography.$font-sizes, caption);
  }

  .input-block__prefix {
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    border-right-width: 1px;
  }

  .input-block__suffix {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    border-left-width: 1px;
  }

  input {
    &.has-prefix {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.has-suffix {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
