/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48I2U4MmE1ZSIsIj9lcjwjZmFkMWRjIiwiO2VyPCM2MTBhMjJ$LCIlPmBePCM0ZTUwNjQiLCI~ZXI8I2UyZTNlOSIsIjtlcjwjMmYzMDNjfiwid2Fybj5gXjwjZGQyMjJjIiwiP2VyPCNmOGQzZDUiLCI7ZXI8Izg0MTQxYX4sIj9UZXh0PCMxMDExMTkiLCI~PTwjZjFmMWY0IiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMxMDExMTl$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjExfQ==
*/
@use '@angular/material' as mat;

@include mat.core();

// Theme Config

$light-primary-text: var(--palette-static-white);
$dark-primary-text: var(--alpha-static-black-90);

$mat-primary: (
  main: #2F3451,
  lighter: #DFE1EC,
  darker: #161927,
  200: #CED2E3, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
  main: var(--tones-main-accent-500),
  lighter: var(--tones-main-accent-100),
  darker: var(--tones-main-accent-700),
  200: var(--tones-main-accent-500), // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary, accent: $theme-accent
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@include mat.all-component-themes($theme);

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

