@use '../tools/button.mixin' as button;

.itera-button,
.itera-link-button {
  @include button.base;

  &[data-size="small"] {
    @include button.size(small);
  }

  &[data-size="medium"]{
    @include button.size(medium);
  }

  &[data-block="true"] {
    width: 100%;
  }
}

a.itera-button:not(.itera-link-button) {
  text-decoration: none;
}

