.flex-grow {
  flex-grow: 1;

  &--2 {
    flex-grow: 2;
  }

  &--3 {
    flex-grow: 3;
  }
}

.flex-no-grow {
  flex-grow: 0;
}
