.notification-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;

  &.positive,
  &.succeeded,
  &.resolved {
    svg-icon svg {
      fill: var(--tones-semantic-positive-700);
    }
  }

  &.negative,
  &.payment-action-required,
  &.firing,
  &.failed {
    svg-icon svg {
      fill: var(--tones-semantic-negative-500);
    }
  }
}
