@use 'sass:map';
@use '../settings/typo-setting' as typography;

$size: 2rem !default;
$size-small: 1.5rem !default;
$size-terminal: 13px !default;
$spacing-x: 0.75rem !default;
$spacing-y: 0 !default;
$spacing-clear: 0 0.25rem !default;
$gap: var(--global-gap-small) !default;
$radius: var(--border-radius) !default;
$easing: 400ms !default;
$outline-border-width: 2px !default;
$font-size: 0.9375rem !default;
$font-size-small: 0.8125rem !default;

@mixin base {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $gap;
  width: auto;
  height: $size;
  margin: 0;
  padding: $spacing-y $spacing-x;
  border-radius: $radius;
  font-size: $font-size;
  font-weight: typography.$font-weight-medium;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color $easing, color $easing;
  pointer-events: auto;

  &:active {
    color: inherit;
  }

  &:disabled,
  &[disabled] {
    pointer-events: none;
  }
}

@mixin color-in($text, $bg, $text-hover, $bg-hover, $disabled-text, $disabled-bg) {
  color: var(#{$text});
  background-color: var(#{$bg});

  &:hover {
    color: var(#{$text-hover});
    background-color: var(#{$bg-hover});
  }

  &:disabled,
  &[disabled] {
    color: var(#{$disabled-text});
    background-color: var(#{$disabled-bg});
  }
}

@mixin color-in-outline($color, $bg-hover, $disabled-color) {
  color: var(#{$color});
  border-color: var(#{$color});

  &:hover {
    color: var(#{$color});
    background-color: var(#{$bg-hover});
  }

  &:disabled,
  &[disabled] {
    color: var(#{$disabled-color});
    border-color: var(#{$disabled-color});
  }
}

@mixin color-in-flat($color, $bg-hover, $disabled-color) {
  color: var(#{$color});

  &:hover {
    background-color: var(#{$bg-hover});
  }

  &:disabled,
  &[disabled] {
    color: var(#{$disabled-color});
  }
}

@mixin color-in-clear($color, $color-hover, $disabled-color) {
  color: var(#{$color});

  &:hover {
    color: var(#{$color-hover});
  }

  &:disabled,
  &[disabled] {
    color: var(#{$disabled-color});
  }
}

.button {
  @include base;
  border: $outline-border-width solid;
  border-color: transparent;

  &.button--small {
    height: $size-small;
    font-size: $font-size-small;
  }

  &.button--block {
    width: 100%;
  }

  &.button--icon {
    width: $size;
    padding: 0;
    overflow: hidden;

    &.button--small {
      width: $size-small;
    }
  }

  &:disabled,
  &[disabled] {
    pointer-events: none;
  }

  &.button--terminal {
    width: $size-terminal;
    height: $size-terminal;
    padding: 0;
    border-radius: $size-terminal;

    &.button--error {
      color: transparent;

      &:hover {
        color: var(--alpha-static-black-90);
        background-color: var(--tones-semantic-negative-300);
      }
    }

    &.button--warning {
      color: transparent;

      &:hover {
        color: var(--alpha-static-black-90);
        background-color: var(--tones-semantic-notice-300);
      }
    }
  }

  &.button--back {
    &,
    &:hover,
    &:disabled,
    &[disabled] {
      background-color: transparent;
      padding: 0;

      > svg-icon {
        font-size: map.get(typography.$font-sizes, xlarge) !important;
      }
    }
  }

  // Color variants

  // Default
  @include color-in(
    --palette-static-black,
    --tones-main-primary-100,
    --palette-static-black,
    --tones-main-primary-200,
    --alpha-static-black-10,
    --alpha-main-primary-700-30
  );

  // Accent
  &.button--primary,
  &.button--accent {
    @include color-in(
      --palette-static-white,
      --tones-main-accent-500,
      --palette-static-white,
      --tones-main-accent-700,
      --alpha-static-white-70,
      --tones-main-accent-100
    );
  }

  // Status
  &.button--error,
  &.button--negative {
    @include color-in(
      --palette-static-white,
      --tones-semantic-negative-500,
      --palette-static-white,
      --tones-semantic-negative-800,
      --alpha-static-white-70,
      --alpha-semantic-negative-10
    );
  }

  &.button--warning,
  &.button--notice {
    @include color-in(
      --palette-static-white,
      --tones-semantic-notice-600,
      --palette-static-white,
      --tones-semantic-notice-800,
      --alpha-static-white-70,
      --alpha-semantic-notice-10
    );
  }

  &.button--success,
  &.button--positive {
    @include color-in(
      --palette-static-black,
      --tones-semantic-positive-600,
      --palette-static-black,
      --tones-semantic-positive-300,
      --alpha-static-black-70,
      --alpha-semantic-positive-10
    );
  }

  &.button--info,
  &.button--informative {
    @include color-in(
      --palette-static-white,
      --tones-semantic-informative-500,
      --palette-static-white,
      --tones-semantic-informative-800,
      --alpha-static-white-70,
      --alpha-semantic-informative-10
    );
  }

  &.button--light {
    @include color-in(
      --palette-static-black,
      --palette-static-white,
      --palette-static-black,
      --alpha-static-white-90,
      --alpha-static-black-70,
      --alpha-static-white-30
    );
  }

  // Variants and their colors
  &.button--flat {
    &,
    &:disabled,
    &[disabled] {
      background-color: transparent;
    }

    // Default
    @include color-in-flat(
      --color-chinegit se-black,
      --alpha-main-primary-700-10,
      --alpha-primary-20
    );

    // Primary
    &.button--primary,
    &.button--accent {
      @include color-in-flat(
        --tones-main-accent-500,
        --alpha-main-accent-500-10,
        --alpha-main-accent-500-30
      );
    }

    // Status
    &.button--error,
    &.button--negative {
      @include color-in-flat(
        --tones-semantic-negative-500,
        --alpha-semantic-negative-10,
        --alpha-semantic-negative-30
      );
    }

    &.button--warning,
    &.button--notice {
      @include color-in-flat(
        --tones-semantic-notice-600,
        --alpha-semantic-notice-10,
        --alpha-semantic-notice-30
      );
    }

    &.button--success,
    &.button--positive {
      @include color-in-flat(
        --tones-semantic-positive-600,
        --alpha-semantic-positive-10,
        --alpha-semantic-positive-30
      );
    }

    &.button--info,
    &.button--informative {
      @include color-in-flat(
        --tones-semantic-informative-500,
        --alpha-semantic-informative-10,
        --alpha-semantic-informative-30
      );
    }

    &.button--kubernetes {
      @include color-in-flat(
        --palette-static-clouds-kubernetes,
        --alpha-kubernetes-10,
        --alpha-kubernetes-30
      );
    }

    &.button--light {
      @include color-in-flat(
        --palette-static-white,
        --alpha-static-white-30,
        --alpha-static-white-30
      );
    }
  }

  &.button--outline {
    background-color: transparent;

    &:disabled,
    &[disabled] {
      background-color: transparent;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.2;
      cursor: default;
    }

    @include color-in-outline(
      --palette-static-black,
      --alpha-main-primary-700-10,
      --alpha-main-primary-700-30
    );

    // Primary
    &.button--primary,
    &.button--accent {
      @include color-in-outline(
        --tones-main-accent-500,
        --alpha-main-accent-500-10,
        --alpha-main-accent-500-30
      );
    }

    // Status
    &.button--error,
    &.button--negative {
      @include color-in-outline(
        --tones-semantic-negative-500,
        --alpha-semantic-negative-10,
        --alpha-semantic-negative-30
      );
    }

    &.button--warning,
    &.button--notice {
      @include color-in-outline(
        --tones-semantic-notice-600,
        --alpha-semantic-notice-10,
        --alpha-semantic-notice-30
      );
    }

    &.button--success,
    &.button--positive {
      @include color-in-outline(
        --tones-semantic-positive-600,
        --alpha-semantic-positive-10,
        --alpha-semantic-positive-30
      );
    }

    &.button--info,
    &.button--informative {
      @include color-in-outline(
        --tones-semantic-informative-500,
        --alpha-semantic-informative-10,
        --alpha-semantic-informative-30
      );
    }

    &.button--kubernetes {
      @include color-in-outline(
        --palette-static-clouds-kubernetes,
        --alpha-kubernetes-10,
        --alpha-kubernetes-30
      );
    }

    &.button--light {
      @include color-in-outline(
        --palette-static-white,
        --alpha-static-white-30,
        --alpha-static-white-30
      );
    }
  }

  &.button--clear {
    &,
    &:hover,
    &:disabled,
    &[disabled] {
      background-color: transparent;
      padding: $spacing-clear;
    }

    // Default
    @include color-in-clear(
      --palette-static-black,
      --tones-main-primary-800,
      --alpha-main-primary-700-30
    );

    // Primary
    &.button--primary,
    &.button--accent {
      @include color-in-clear(
        --tones-main-accent-500,
        --tones-main-accent-800,
        --alpha-main-accent-500-30
      );
    }

    // Status
    &.button--error,
    &.button--negative {
      @include color-in-clear(
        --tones-semantic-negative-500,
        --tones-semantic-negative-800,
        --alpha-semantic-negative-30
      );
    }

    &.button--warning,
    &.button--notice {
      @include color-in-clear(
        --tones-semantic-notice-600,
        --tones-semantic-notice-800,
        --alpha-semantic-notice-30
      );
    }

    &.button--success,
    &.button--positive {
      @include color-in-clear(
        --tones-semantic-positive-600,
        --tones-semantic-positive-800,
        --alpha-semantic-positive-30
      );
    }

    &.button--info,
    &.button--informative {
      @include color-in-clear(
        --tones-semantic-informative-500,
        --tones-semantic-informative-800,
        --alpha-semantic-informative-30
      );
    }

    &.button--kubernetes {
      @include color-in-clear(
        --palette-static-clouds-kubernetes,
        --palette-static-clouds-kubernetes-800,
        --alpha-kubernetes-30
      );
    }

    &.button--light {
      @include color-in-clear(
        --palette-static-white,
        --alpha-static-white-70,
        --alpha-static-white-30
      );
    }
  }
}

a.button {
  box-sizing: border-box;
  text-decoration: none;
}
