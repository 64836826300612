@use 'sass:map';
@use '../tools/breakpoint.mixin' as bp;
@use '../../scss/settings/typo-setting' as typography;

.notification-trigger {
  position: relative;
  height: 100%;
  padding: 0 20px;
  border: none;
  background: none;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    color: inherit;
  }

  &.has-payment-notification {
    svg-icon {
      fill: var(--tones-main-accent-500);
    }
  }
}

.notification-main-badge {
  position: absolute;
  top: 1rem;
  right: .75rem;
  width: .5rem;
  height: .5rem;
  border-radius: .5rem;
  background-color: var(--tones-main-accent-500);
  transform: scale3d(0, 0, 0);
  animation: badgeFadeIn 400ms cubic-bezier(0.84, 0, 1, 1) forwards;

  @keyframes badgeFadeIn {
    to {
      transform: scale3d(1, 1, 1);
    }
  }
}

.notifications-container {
  max-height: 400px;
  overflow: hidden;

  perfect-scrollbar > .ps {
    max-height: 400px;
  }
}

.notification-item {
  position: relative;
  display: flex;
  gap: .5rem;
  margin: 0 .5rem .5rem;
  padding: .5rem;
  border-radius: var(--global-radius);
  background-color: var(--tones-main-primary-50);
  cursor: pointer;
  transition: background-color 450ms ease;

  &:hover {
    background-color: var(--tones-main-primary-100);
  }

  .notification-date {
    font-size: map.get(typography.$font-sizes, caption);
  }

  .notification-title {
    padding-right: 1rem;
    font-size: map.get(typography.$font-sizes, caption);
    font-weight: typography.$font-weight-medium;
  }

  &.is-unread {
    &::before {
      position: absolute;
      top: .5rem;
      right: .5rem;
      display: block;
      width: .5rem;
      height: .5rem;
      border-radius: .5rem;
      background-color: var(--tones-main-accent-500);
      content: '';
    }

    .notification-title {
      font-weight: typography.$font-weight-bold;
    }

    .notification-date {
      font-weight: typography.$font-weight-medium;

      &.succeeded,
      &.resolved {
        color: var(--tones-semantic-positive-700);
      }

      &.payment-action-required,
      &.firing,
      &.failed {
        color: var(--tones-semantic-negative-500);
      }
    }
  }
}

.menu-content-empty {
  min-width: 240px;
  padding: 2rem 1rem;
  font-size: map.get(typography.$font-sizes, caption);
  text-align: center;
  color: var(--tones-main-primary-800);
}

.cdk-overlay-pane .mat-menu-panel {
  width: 280px !important;
  max-width: 100% !important;
  box-shadow: var(--shadow-default-6);
  border-radius: 0 0 var(--global-radius) var(--global-radius);

  @include bp.breakpoint(medium) {
    width: 480px !important;
  }
}

.mat-menu-content {
  padding: 0 !important;
}

.button.button--notifications-footer {
  border-radius: 0 0 var(--global-radius) var(--global-radius);
  border-top: 1px solid var(--color-border);
}
