.cdk-drag-preview {
  .ticket {
    animation: rotateDrag .2s linear forwards;
  }
}

.cdk-drag-placeholder {
  background: var(--alpha-main-accent-500-10);
  border: 2px dotted var(--tones-main-accent-500);
  border-radius: .5rem;

  .ticket {
    opacity: 0;
  }
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

@keyframes rotateDrag {
  from {
    transform: rotate(0deg);
    box-shadow: none;
  }

  to {
    transform: rotate(-2deg);
    box-shadow: var(--shadow-default-5);
  }
}
