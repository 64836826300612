.form-array-section {
  border: 2px solid var(--color-border);
  border-radius: var(--border-radius);
  padding: 1rem;

  .form-array-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .form-array-section-row {
    display: flex;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.form-array-section-no-data {
  color: var(--tones-main-primary-400);
  text-align: center;
}
