.itera-pop-up-overlay {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding: .5rem;
  box-shadow: var(--shadow-default-3);
  background-color: var(--palette-static-white);
  border-radius: var(--global-radius);
  border: 1px solid var(--color-border-100);
}
