@use 'sass:map';
@use 'sass:math';
@import '../tokens';

@mixin _heading-base() {
  font-family: var(--base-font-family);
  font-weight: var(--heading-weight-normal);
  line-height: var(--heading-line-height);
  color: var(--text-color, var(--palette-static-black));
}

$heading-transform: (
  'none': none,
  'titlecase': var(--heading-transform),
);

$heading-size: (
  'small': 0.8,
  'normal': 1,
);

@mixin heading-size($initial, $key: normal) {
  @if not map.has-key($heading-size, $key) {
    @error "For Header size you can only pass: #{map.keys($heading-size)} ";
  }
  font-size: calc(var($initial) * map.get($heading-size, $key));
}

@mixin heading-transform($key: titlecase) {
  @if not map.has-key($heading-transform, $key) {
    @error "For Header transform you can only pass: #{map.keys($heading-transform)} ";
  }

  text-transform: map.get($heading-transform, $key);
}

@mixin h1($heading-transform: titlecase, $size: normal) {
  @include _heading-base;
  @include heading-transform($heading-transform);
  @include heading-size(--heading-size-xxl, $size);
}

@mixin h2($heading-transform: titlecase, $size: normal) {
  @include _heading-base;
  @include heading-transform($heading-transform);
  @include heading-size(--heading-size-xl, $size);
}

@mixin h3($heading-transform: titlecase, $size: normal) {
  @include _heading-base;
  @include heading-transform($heading-transform);
  @include heading-size(--heading-size-l, $size);
}

@mixin h4($heading-transform: titlecase, $size: normal) {
  @include _heading-base;
  @include heading-transform($heading-transform);
  @include heading-size(--heading-size-m, $size);
}

@mixin h5($heading-transform: titlecase, $size: normal) {
  @include _heading-base;
  @include heading-transform($heading-transform);
  @include heading-size(--heading-size-s, $size);
}

@mixin h6($heading-transform: titlecase, $size: normal) {
  @include _heading-base;
  @include heading-transform($heading-transform);
  @include heading-size(--heading-size-xs, $size);
}
