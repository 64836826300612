.text {
  &-align {
    &--center {
      text-align: center !important;
    }

    &--justify {
      text-align: justify !important;
    }

    &--left {
      text-align: left !important;
    }

    &--right {
      text-align: right !important;
    }
  }
}
