/*
Based on this flexbox implementation
https://css-tricks.com/responsive-designs-and-css-custom-properties-building-a-flexible-grid-system/

DEFAULT COLUMN AMOUNT IS 12
*/

.grid-container {
  --columns: 12; /* Number of columns in the grid system */
  //--gap: 20px; // can be overwritten in the html, i.e. <div class="grid-container" style="--gap: 30px">
  --gap-vertical: 0; // can be overwritten in the html, i.e. <div class="grid-container" style="--gap-vertical: 30px">
  --gap-horizontal: 20px; // can be overwritten in the html, i.e. <div class="grid-container" style="--gap-horizontal: 30px">

  //display: grid;
  //grid-auto-columns: 1fr;
  //grid-template-columns: repeat(var(--columns), 1fr);
  //gap: var(--gap);

  //width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .grid-item {
    --width: 12; /* Default width of the element */

    //grid-column: span var(--width);
    flex: 1 1 0;
  }
}
