@use 'sass:map';
@use '../tools/breakpoint.mixin' as bp;
@use '../settings/breakpoint-setting' as bpset;

.align {
  &--items {
    &--start {
      align-items: flex-start !important;
    }

    &--end {
      align-items: flex-end !important;
    }

    &--center {
      align-items: center !important;
    }

    &--stretch {
      align-items: stretch !important;
    }

    @each $bp, $bpvalue in bpset.$breakpoints {
      @include bp.breakpoint($bp) {
        &--start--#{$bp} {
          align-items: flex-start !important;
        }

        &--end--#{$bp} {
          align-items: flex-end !important;
        }

        &--center--#{$bp} {
          align-items: center !important;
        }

        &--stretch--#{$bp} {
          align-items: stretch !important;
        }
      }
    }
  }

  &--self {
    &--start {
      align-self: flex-start !important;
    }

    &--end {
      align-self: flex-end !important;
    }

    &--center {
      align-self: center !important;
    }

    &--stretch {
      align-self: stretch !important;
    }

    @each $bp, $bpvalue in bpset.$breakpoints {
      @include bp.breakpoint($bp) {
        &--start--#{$bp} {
          align-self: flex-start !important;
        }

        &--end--#{$bp} {
          align-self: flex-end !important;
        }

        &--center--#{$bp} {
          align-self: center !important;
        }

        &--stretch--#{$bp} {
          align-self: stretch !important;
        }
      }
    }
  }
}

.justify {
  &--content {
    &--start {
      justify-content: flex-start !important;
    }

    &--end {
      justify-content: flex-end !important;
    }

    &--center {
      justify-content: center !important;
    }

    &--space-between {
      justify-content: space-between !important;
    }

    &--space-around {
      justify-content: space-around !important;
    }

    @each $bp, $bpvalue in bpset.$breakpoints {
      @include bp.breakpoint($bp) {
        &--start--#{$bp} {
          justify-content: flex-start !important;
        }

        &--end--#{$bp} {
          justify-content: flex-end !important;
        }

        &--center--#{$bp} {
          justify-content: center !important;
        }

        &--space-between--#{$bp} {
          justify-content: space-between !important;
        }

        &--space-around--#{$bp} {
          justify-content: space-around !important;
        }
      }
    }
  }

  &--self {
    &--start {
      justify-self: flex-start !important;
    }

    &--end {
      justify-self: flex-end !important;
    }

    &--center {
      justify-self: center !important;
    }

    @each $bp, $bpvalue in bpset.$breakpoints {
      @include bp.breakpoint($bp) {
        &--start--#{$bp} {
          justify-self: flex-start !important;
        }

        &--end--#{$bp} {
          justify-self: flex-end !important;
        }

        &--center--#{$bp} {
          justify-self: center !important;
        }
      }
    }
  }
}
