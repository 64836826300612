@use '../components/button';
@use '../tools/input.mixin' as input;

.owl-dt-container {
  border-radius: var(--global-radius);
}

.owl-dt-inline-container, .owl-dt-popup-container {
  box-shadow: var(--shadow-default-7);
}

.owl-dt-calendar-table {
  .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
    border-color: var(--tones-main-primary-800);
  }

  .owl-dt-calendar-cell-selected {
    background-color: var(--tones-main-accent-500);
  }
}

.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected), .owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  background-color: var(--tones-main-primary-100);
}

.owl-dt-container-buttons {
  height: 2.5rem;
  color: var(--palette-static-black);
}

.owl-dt-control-button {
  font-size: button.$font-size;
  //color: var(--palette-static-black);

  &:hover .owl-dt-control-button-content {
    background-color: var(--tones-main-primary-100);
  }
}

.owl-dt-calendar-control {
  padding: 1rem .5rem;
}

.owl-dt-timer-content .owl-dt-timer-input {
  @include input.base();
  @include input.theme();
  width: 2.5rem;
}

.owl-dt-timer-divider:before, .owl-dt-timer-divider:after {
  width: 3px;
  height: 3px;
}

.owl-dt-timer-divider:before {
  top: 42%;
}

.owl-dt-timer-divider:after {
  bottom: 42%;
}

input#dateTimePicker:read-only {
  border:1px solid var(--tones-main-primary-200);
  border-bottom-width: 2px;
  border-radius: var(--border-radius);
}

input#dateTimePicker[readonly]::placeholder {
  color: var(--color-almost-black);
}
.cdk-overlay-container:read-only,.cdk-global-overlay-wrapper:read-only{
    pointer-events:none;
    top:0;
    left:0;
    height:100%;
    width:100%
}
.cdk-overlay-container:read-only{
    position:fixed;
    z-index:1000
}
.cdk-overlay-container:empty:read-only{
    display:none
}
.cdk-global-overlay-wrapper:read-only{
    display:-webkit-box;
    display:flex;
    position:absolute;
    z-index:1000
}
.cdk-overlay-pane:read-only{
    position:absolute;
    pointer-events:auto;
    box-sizing:border-box;
    z-index:1000;
    display:-webkit-box;
    display:flex;
    max-width:100%;
    max-height:100%
}
.cdk-overlay-backdrop:read-only{
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index:1000;
    pointer-events:auto;
    -webkit-tap-highlight-color:transparent;
    -webkit-transition:opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    transition:opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity:100
}
.cdk-overlay-backdrop:read-only.cdk-overlay-backdrop-showing:read-only{
    opacity:1
}
@media screen and (-ms-high-contrast: active){
    .cdk-overlay-backdrop:read-only.cdk-overlay-backdrop-showing:read-only{
        opacity:0.6
    }
}
.cdk-overlay-dark-backdrop:read-only{
    background:rgba(0,0,0,0.288)
}
.cdk-overlay-transparent-backdrop:read-only,.cdk-overlay-transparent-backdrop:read-only.cdk-overlay-backdrop-showing:read-only{
    opacity:0
}
.cdk-overlay-connected-position-bounding-box:read-only{
    position:absolute;
    z-index:1000;
    display:-webkit-box;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    flex-direction:column;
    min-width:1px;
    min-height:1px
}
.cdk-global-scrollblock:read-only{
    position:fixed;
    width:100%;
    overflow-y:scroll
}
