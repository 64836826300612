@use 'sass:map';
@use '../settings/typo-setting' as typoset;

:root {
  @each $name, $size in typoset.$font-sizes {
    --font-size-#{$name}: #{$size};
  }
}

@each $font, $size in typoset.$font-sizes {
  .font-size--#{$font} {
    font-size: $size;
  }
}
