@use '../tools/breakpoint.mixin' as bp;
@use '../settings/typo-setting' as typography;

.mat-slider-thumb-label-text {
  font-size: 12px;
  color: black !important;
}

.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
  background-color: var(--tones-semantic-informative-500);
}

mat-dialog-container {
  &.mat-dialog-container {
    padding: 0;
    width: auto;
    height: auto;
    box-shadow: var(--shadow-dark-7);
  }
}

.mat-slider {
  width: 100%;
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: var(--tones-main-accent-500);
}

.example-full-width {
  width: 100%;
}

.mat-form-field-wrapper {
  width: 100% !important;
}

.ng-touched.ng-invalid .mat-form-field-label {
  color: var(--alpha-static-black-50);
}

.ng-touched.ng-invalid .mat-input-element {
  color: var(--alpha-static-black-50);
}

.ng-touched.ng-invalid .mat-form-field-underline,
.ng-touched.ng-invalid .mat-form-field-ripple {
  background-color: var(--alpha-static-black-50);
}

mat-form-field.ng-dirty.ng-invalid .mat-form-field-label {
  color: var(--tones-semantic-negative-500);
}

mat-form-field.ng-dirty.ng-invalid .mat-form-field-underline,
.ng-dirty.ng-invalid .mat-form-field-ripple {
  background-color: var(--tones-semantic-negative-500);
}

.ng-untouched.ng-invalid .mat-form-field-label {
  color: var(--alpha-static-black-50);
}

.ng-untouched.ng-invalid .mat-input-element {
  color: var(--alpha-static-black-50);
}

.ng-untouched.ng-invalid .mat-form-field-underline,
.ng-untouched.ng-invalid .mat-form-field-ripple {
  background-color: var(--alpha-static-black-50);
}

.form .form-field mat-checkbox label {
  margin-bottom: 0;
}

.mat-checkbox-background,
.mat-checkbox-frame {
  border-radius: 5px !important;
}

.mat-error {
  display: block;
  font-size: 13px;
  margin-top: 5px;
  line-height: 16px;
}

.toggle-fieldset-container {
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-16px, -20px);
}

mat-tab-header.mat-tab-header {
  width: 100%;
  border-bottom: none;
}

mat-tab-group {
  .mat-tab-label {
    opacity: 1;
    min-width: initial;
  }

  .mat-ink-bar {
    height: 3px;
  }
}

.mat-tab-label-active {
  color: var(--tones-main-accent-500);
  font-weight: 700;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--tones-main-accent-500);
}

mat-tab-header.mat-tab-header {
  width: 100%;
  border-bottom: none;
}

.mat-autocomplete-panel {
  border-radius: var(--global-radius) !important;
  padding: 0.5rem;

  &:not([class*='mat-elevation-z']) {
    box-shadow: var(--shadow-default-3);
  }
}

.mat-option {
  font-size: typography.$font-size;
  height: 2rem !important;
  border-radius: var(--global-radius-small);
  padding: 0 0.5rem !important;

  // options background
  &.mat-active,
  &:hover:not(.mat-option-disabled),
  &:focus:not(.mat-option-disabled) {
    background: var(--tones-main-primary-50) !important;
  }
}
