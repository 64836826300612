@use 'sass:map';
@use 'sass:color';
@use 'sass:list';
@use '../settings/shadows-setting' as shadows;

$shadow-colors: shadows.$colors;

$shadow-colors: map.merge((
  default: hsl(218, 15%, 60%),
  dark: hsl(235, 20%, 8%),
  informative: hsla(212, 86%, 50%, 1),
  positive: hsla(108, 54%, 34%, 1),
  notice: hsla(40, 98%, 50%, 1),
  negative: hsla(357, 73%, 50%, 1),
), $shadow-colors);

// If it works, don't touch it
@function give-me-shadow($n, $color) {
  $shadow: null;

  @for $i from 1 through $n {
    $part-size: ($i * $i * 2) * 1px;
    $shadow: list.append($shadow, '0 #{$part-size} #{$part-size} hsla(#{$color})', $separator: comma);
  }
  @return $shadow;
}

@function give-me-shadow-color($color) {
  $a: shadows.$alpha;
  $new-color-hue: color.hue($color);
  $new-color-saturation: color.saturation($color);
  $new-color-lightness: color.lightness($color);
  $hsl-color: #{$new-color-hue} + ',' + #{$new-color-saturation} + ',' + #{$new-color-lightness};

  @return '#{$hsl-color}, #{$a}';
}

:root {
  @each $color, $value in $shadow-colors {
    $shadow-color: give-me-shadow-color($value);

    @for $i from 1 through shadows.$levels {
      $box-shadow: give-me-shadow($i, $shadow-color);

      --shadow-#{$color}-#{$i}: #{$box-shadow};
    }
  }
}

@each $color, $value in $shadow-colors {
  @for $i from 1 through shadows.$levels {
    .box-shadow--#{$color}--#{$i} {
      box-shadow: var(--shadow-#{$color}-#{$i});
    }
  }
}
