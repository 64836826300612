.table-responsive-container {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-spacing: 0;
  color: var(--color-text);
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid var(--color-border-200);
  background-color: var(--palette-static-white);
  overflow: hidden;

  thead {
    background-color: var(--tones-main-primary-25);
  }

  th {
    text-align: left;
    padding: 1rem 0.75rem;
    font-size: 12px;
    line-height: 17px;
    border-bottom: 1px solid var(--color-border-200);
    vertical-align: middle;
    white-space: nowrap;

    &.right {
      text-align: right;
    }

    .one-line--icon-items {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      gap: 0.25rem;

      svg-icon {
        fill: var(--tones-main-primary-800);
      }
    }

    &.one-line {
      white-space: nowrap;
    }

    &.icon-column {
      width: 2rem;
    }
  }

  tr {
    td {
      position: relative;
      padding: 0.75rem;
      border-bottom: 1px solid var(--tones-main-primary-200);
      text-align: left;
      font-size: 14px;
      vertical-align: middle;

      &.right {
        text-align: right;
      }

      &.one-line {
        white-space: nowrap;
      }

      .one-line--icon-items {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        gap: 0.25rem;

        svg-icon {
          fill: var(--tones-main-primary-800);
        }
      }

      .state-label {
        position: absolute;
        left: 1px;
        top: 2px;
        bottom: 2px;
        width: 4px;
        border-radius: 4px;

        &.succeeded {
          background-color: var(--tones-semantic-positive-600);
        }

        &.failed {
          background-color: var(--tones-semantic-negative-500);
        }

        &.warning {
          background-color: var(--tones-semantic-notice-600);
        }

        &.normal {
          background-color: var(--tones-semantic-positive-600);
        }
      }
    }

    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  tfoot {
    background-color: rgba(230, 43, 94, 0.05);
    font-weight: 700;

    .tfoot-highlight {
      color: var(--tones-main-accent-500);
    }

    td {
      border-top: 1px solid var(--color-border-200);
    }
  }

  th,
  td {
    &.table__col {
      &--id {
        width: 64px;
      }

      &--size {
        width: 80px;
      }

      &--protocol,
      &--ip,
      &--common {
        width: 144px;
      }

      &--ports {
        width: 160px;
      }
    }
  }

  .collapse-handler {
    min-width: auto;
    cursor: pointer;
    padding: 0 17px;
    width: 40px;
    border-bottom: none;
    border-left: 1px solid var(--color-border-200);
    background-color: var(--palette-static-white);

    &:hover {
      background-color: var(--tones-main-primary-50);
    }
  }

  .collapsible {
    transition: all 0.3s ease-in-out;

    &:not(.collapsed) {
      animation: collapseColor 1.5s;
    }

    &.collapsed {
      display: none;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
  }

  .table-select {
    min-width: auto;
  }
}

.table--no-shadow {
  tbody {
    box-shadow: none;
  }
}

.table--lined {
  tbody tr:nth-child(odd) {
    background-color: var(--tones-main-primary-50);
  }

  tr td {
    border-bottom: none;
  }
}

.one-line--icon-items {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  gap: 0.25rem;

    svg-icon {
      fill: var(--tones-main-primary-800);
    }
}
