@use 'button';

.button-group {
  display: flex;
  gap: 2px;

  .button {
    border-radius: 0;
    background-color: transparent;

    &:disabled,
    &[disabled] {
      background-color: transparent;
    }

    &:not(.is-active) {
      @include button.color-in-outline(
        --tones-main-accent-500,
        --alpha-main-accent-500-10,
        --alpha-main-accent-500-30
      );
    }

    &.is-active {
      @include button.color-in(
        --palette-static-white,
        --tones-main-accent-500,
        --palette-static-white,
        --tones-main-accent-700,
        --alpha-static-white-70,
        --alpha-main-accent-500-10
      );
    }

    &:last-child {
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
    }

    &:first-child {
      border-radius: var(--border-radius) 0 0 var(--border-radius);
    }
  }
}
