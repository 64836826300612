@use '../settings/typo-setting' as vars;

.font-weight {
  &--regular,
  &--normal {
    font-weight: vars.$font-weight-normal !important;
  }

  &--medium {
    font-weight: vars.$font-weight-medium !important;
  }

  &--semibold {
    font-weight: vars.$font-weight-semibold !important;
  }

  &--bold {
    font-weight: vars.$font-weight-bold !important;
  }
}
