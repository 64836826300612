.no-data-error {
  text-align: center;
  padding: 20px 0;
  font-size: 15px;
  color: var(--tones-main-primary-500);

  p {
    padding: 5px 0;
  }

  a {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}
