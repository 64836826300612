@use '../settings/typo-setting' as typography;

fieldset {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 2px solid var(--tones-main-primary-200);
  margin-bottom: 2rem;
  gap: 1rem;

  legend {
    padding: 0 0.25rem;
    color: var(--tones-main-primary-400);
    font-size: typography.$font-size-sm;
    font-weight: typography.$font-weight-medium;
    align-self: start;
  }
}
