$breakpoints: (
  'xxs': 374px,
  'xs': 424px,
  's': 600px,
  'm': 767px,
  'l': 1023px,
  'l-desktop': 1200px,
  'xl': 1439px,
  'xxl': 1979px,
) !default;

@mixin screen($breakpoint) {
  @if map_has_key($breakpoints, $breakpoint) {
    @media (max-width: map_get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn 'No such breakpoint. Available: #{map_keys($breakpoints)}';
  }
}
