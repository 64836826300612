// Common input styles
// Usage: input, select, search...

@use 'sass:map';
@use '../settings/typo-setting' as typography;

$width: 100% !default;
$height: 2rem !default;
$spacing-y: .25rem !default;
$spacing-x: .5rem !default;
$spacing: $spacing-y $spacing-x !default;
$body: var(--palette-static-white) !default;
$text: var(--global-color-text) !default;
$text-disabled: var(--tones-main-primary-200) !default;
$radius: var(--border-radius) !default;
$border-width: 1px !default;
$border-bottom-width: 2px !default;
$border-color: var(--color-border) !default;
$border-color-hover: var(--color-border-hover) !default;
$border-color-disabled: var(--color-border-disabled) !default;
$border-color-readonly: var(--color-border-readonly) !default;
$invalid-border-color: var(--tones-semantic-negative-500) !default;
$outline-focus: var(--tones-main-primary-25) 0 0 0 2px, var(--tones-main-primary-400) 0 0 0 4px !default;
$invalid-outline-focus: var(--tones-main-primary-25) 0 0 0 2px, var(--tones-semantic-negative-500) 0 0 0 4px !default;

@mixin base($base-spacing: $spacing) {
  display: flex;
  align-items: center;
  width: $width;
  height: $height;
  padding: $base-spacing;
  border-style: solid;
  border-width: $border-width;
  border-bottom-width: $border-bottom-width;
  border-radius: $radius;
  outline: none;
  transition: border-color var(--global-easing-time) var(--global-easing), box-shadow var(--global-easing-time) var(--global-easing);
}

@mixin theme {
  border-color: $border-color;
  background-color: $body;

  &:hover {
    border-color: $border-color-hover;
  }

  &[disabled] {
    border-color: var(--color-border-disabled);
    background-color: var(--color-background-disabled);

    &::placeholder {
      color: var(--tones-main-primary-200);
    }
  }

  &[readonly] {
    border-color: var(--color-border-readonly);

    &::placeholder {
      color: transparent;
    }
  }

  &,
  &[readonly] {
    &.ng-invalid.ng-touched,
    &.ng-invalid.ng-dirty {
      border-color: $invalid-border-color !important;
      //box-shadow: $invalid-outline-focus;
    }
  }
}

@mixin typography {
  font-size: map.get(typography.$font-sizes, input);

  &::placeholder {
    color: var(--color-placeholder);
  }
}

@mixin focus($focus-within: false) {

  // ToDo(Radim): solve box-shadow of Focus when input is cut by edges of its parent
  // E.g. Project - gitlab-runner/Backup Policy/Restores tab

  @if $focus-within {
    &:focus-within {
      border-color: $border-color-hover;
      //box-shadow: $outline-focus;
    }

    &.ng-invalid.ng-dirty {
      &:focus-within {
        border-color: $invalid-border-color;
        //box-shadow: $invalid-outline-focus;
      }
    }
  } @else {
    &:focus {
      border-color: $border-color-hover;
      //box-shadow: $outline-focus;
    }

    &.ng-invalid.ng-dirty {
      &:focus {
        border-color: $invalid-border-color;
        //box-shadow: $invalid-outline-focus;
      }
    }
  }
}

@mixin all-input-styles($focus-is-within: false) {
  @include base;
  @include theme;
  @include focus($focus-within: $focus-is-within);
  @include typography;
}
