.highlight-row {
  animation: collapseColor 1.5s;
}

@keyframes collapseColor {
  0% {
    background-color: var(--tones-semantic-notice-600);
  }

  40% {
    background-color: var(--tones-semantic-notice-600);
  }

  100% {
    background-color: transparent;
  }
}
