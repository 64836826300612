.logo-name-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;

  img {
    max-height: 20px;
  }
}
