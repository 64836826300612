@use "../settings/typo-setting" as typography;

.card-header {
  padding: 1.5rem;
  border-bottom: 2px solid var(--color-border);
}

.card-body {
  padding: 1.5rem;
}

.card-item {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .svg-icon-general\:check-circle {
    color: var(--tones-semantic-positive-600);
  }

  .svg-icon-general\:x-circle {
    color: var(--tones-semantic-negative-500);
  }
}

.card-hint {
  margin-top: 0.5rem;
  font-size: typography.$font-size-sm;
  color: var(--tones-main-primary-800);
}

.card-footer {
  margin-top: 1.5rem;
  text-align: center;
}
