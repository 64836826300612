$size: 1px !default;
$vertical-size: 1.5em !default;
$spacing: .5rem !default;

.divider {
  display: block;
  background-color: var(--alpha-main-primary-700-50);

  &--horizontal {
    height: $size;
    width: 100%;
  }

  &--vertical {
    flex: 0 0 $size;
    width: $size;
    height: $vertical-size;
    margin: 0 $spacing;

    &.divider--font-size {
      height: 1em;
    }

    &.divider--full {
      height: auto;
      margin: 0;
    }
  }

  &.divider--light {
    background-color: var(--alpha-static-white-30);
  }

  &.divider--dark {
    background-color: var(--alpha-static-black-30);
  }
}
