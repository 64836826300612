@use '../settings/typo-setting' as typography;

.terminal-look {
  overflow: auto;
  padding: 1rem 1.5rem;
  max-height: 75vh;
  border: none;
  background-color: var(--palette-static-black);
  font-size: typography.$font-size-mono;
  font-family: typography.$font-family-monospace;
  color: var(--tones-main-primary-50);
  line-height: 24px;
}
