:root {
  --base-font-family: 'Inter', sans-serif;
  --code-font-family: 'Roboto Mono', monospace;

  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;

  --font-size-50: 0.75rem;
  --font-size-75: 0.875rem;
  --font-size-100: 1rem;
  --font-size-200: 1.125rem;
  --font-size-300: 1.25rem;
  --font-size-400: 1.375rem;
  --font-size-500: 1.625rem;
  --font-size-600: 1.875rem;
  --font-size-700: 2.125rem;
  --font-size-800: 2.375rem;
  --font-size-900: 2.75rem;
  --font-size-1000: 3.125rem;

  --text-transform-uppercase: uppercase;
  --text-transform-lowercase: lowercase;
  --text-transform-titlecase: capitalize;
  --text-transform-strong: strong;
  --text-transform-italic: italic;
  --text-transform-underline: underline;
  --text-transform--none: none;

  --line-height-130: 130%;
  --line-height-150: 150%;
}
