@use '../settings/typo-setting' as typography;

.status-label {
  display: flex;
  height: 2rem;
  padding: .25rem .5rem;
  align-items: center;
  font-size: typography.$font-size-sm;
  color: var(--palette-static-white);
  border-radius: 3px;

  &.status-label--inactive,
  &.status-label--success {
    background-color: var(--tones-semantic-positive-600);
  }

  &.status-label--pending,
  &.status-label--warning {
    background-color: var(--tones-semantic-notice-500);
  }

  &.status-label--firing,
  &.status-label--danger,
  &.status-label--error {
    background-color: var(--tones-semantic-negative-500);
  }

  &.status-label--info {
    background-color: var(--tones-semantic-informative-500);
  }

  &.status-label--primary {
    background-color: var(--tones-main-accent-500);
  }

  &.status-label--secondary {
    background-color: var(--tones-main-primary-800);
  }

  &.status-label--outline {
    border: 2px solid;

    &.status-label--inactive,
    &.status-label--success {
      background-color: var(--tones-semantic-positive-100);
      border-color: var(--tones-semantic-positive-600);
      color: var(--tones-semantic-positive-700);
    }

    &.status-label--pending,
    &.status-label--warning {
      background-color: var(--tones-semantic-notice-100);
      border-color: var(--tones-semantic-notice-500);
      color: var(--tones-semantic-notice-700);
    }

    &.status-label--firing,
    &.status-label--danger,
    &.status-label--error {
      background-color: var(--tones-semantic-negative-100);
      border-color: var(--tones-semantic-negative-500);
      color: var(--tones-semantic-negative-700);
    }

    &.status-label--info {
      background-color: var(--tones-semantic-informative-100);
      border-color: var(--tones-semantic-informative-500);
      color: var(--tones-semantic-informative-700);
    }

    &.status-label--accent {
      background-color: var(--tones-main-accent-100);
      border-color: var(--tones-main-accent-500);
      color: var(--tones-main-accent-700);
    }

    &.status-label--primary {
      background-color: var(--tones-main-primary-100);
      border-color: var(--tones-main-primary-800);
      color: var(--tones-main-primary-700);
    }
  }
}
