@keyframes shakeX {
    from,
    to {
      transform: translate3d(0, 0, 0);
    }
  
    3%,
    9%,
    15% {
      transform: translate3d(-10px, 0, 0);
    }
  
    6%,
    12%,
    18% {
      transform: translate3d(10px, 0, 0);
    }
  
    18% {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .deploy-animation {
    animation-name: shakeX;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 2s;
  
    &:hover {
      animation-play-state: paused;
    }
  }
  