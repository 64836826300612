@use '../settings/typo-setting' as typography;

.query-command-line {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  gap: 0.5rem;
  background-color: var(--color-terminal-background);

  > input {
    &::placeholder {
      color: var(--tones-main-primary-200);
    }

    &:focus {
      box-shadow: none;
    }
  }

  .mat-radio-button {
    margin-right: 1rem;

    label {
      color: var(--palette-static-white);
    }

    .mat-radio-outer-circle {
      border-color: var(--palette-static-white);
    }
  }
}

.query-command-line-input.mat-input-element,
.query-command-line-input {
  background-color: transparent !important;
  color: var(--palette-static-white);
  flex-grow: 1;
  border: none !important;
  outline: none;
  padding: 8px 16px;
  line-height: 1.2em;
  font-size: typography.$font-size-mono;
  font-family: typography.$font-family-monospace;
  caret-color: var(--palette-static-white);
}

.query-additional-info {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 13px;

  .query-additional-info-query-example {
    font-size: typography.$font-size-mono;
    font-family: typography.$font-family-monospace;
    border-bottom: 1px dashed;
    cursor: pointer;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  .fa {
    font-size: 10px;
  }
}
