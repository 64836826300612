@use '../utils/ellipsis.util';

.charts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.chart-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: var(--palette-static-white);
  border-radius: .5rem;
  border: 2px solid var(--color-border);
}

.chart-section-heading {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 2em 0 1.5em;
}

.chart-card-heading {
  @include ellipsis.ellipsis;
}

.chart-card-footer {
  font-size: 14px;
  padding-top: 10px;
  text-align: center;
}
