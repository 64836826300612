markdown {
  display: block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1em 0 .75em;
  }

  p,
  pre,
  ul,
  ol,
  table {
    margin-bottom: 1rem;
  }

  p,
  ul li,
  ol li {
    code {
      padding: 2px;
      border-radius: 2px;
      border: 1px solid var(--tones-main-primary-200);
      background-color: var(--tones-main-primary-50);
    }
  }

  pre {
    overflow: auto;
  }

  blockquote {
    padding-left: .5rem;
    border-radius: 3px;
    border-left: 3px solid var(--tones-main-primary-300);
  }

  table {
    display: block;
    border-spacing: 0;
    color: var(--color-text);
    font-size: 15px;
    border-radius: var(--global-radius);
    border: none;
    background-color: var(--palette-static-white);
    width: auto;
    max-width: 100%;
    overflow: auto;

    thead {
      background-color: var(--palette-static-white);
    }

    th {
      text-align: left;
      padding: 1rem .75rem;
      font-size: 13px;
      line-height: 17px;
      border-top: 1px solid var(--color-border);
      border-bottom: 2px solid var(--color-border-100);
      vertical-align: middle;

      &:first-child {
        border-top-left-radius: var(--global-radius);
        border-left: 1px solid var(--color-border);
      }

      &:last-child {
        border-top-right-radius: var(--global-radius);
        border-right: 1px solid var(--color-border);
      }
    }

    tr {
      td {
        position: relative;
        padding: .75rem;
        text-align: left;
        font-size: 14px;
        vertical-align: middle;

        &:first-child {
          border-left: 1px solid var(--color-border);
        }

        &:last-child {
          border-right: 1px solid var(--color-border);
        }
      }

      &:nth-child(even) {
        background-color: var(--tones-main-primary-50);
      }

      &:last-child {
        td {
          border-bottom: 1px solid var(--color-border);

          &:first-child {
            border-bottom-left-radius: var(--global-radius);
          }

          &:last-child {
            border-bottom-right-radius: var(--global-radius);
          }
        }
      }
    }
  }
}
