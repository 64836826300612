@use 'sass:color';

:root {
  --palette-main-accent: #7D58F7FF;
  --palette-main-primary: #3A3C45FF;
  --palette-static-white: #FFFFFFFF;
  --palette-static-black: #101119FF;
  --palette-static-brand-a: #7D58F7FF;
  --palette-static-brand-b: #67B1F9FF;
  --palette-static-brand-c: #F7DE58FF;
  --palette-static-brand-d: #A8E890FF;
  --palette-static-clouds-kubernetes: #326CE5;
  --palette-static-clouds-aws: #FF9900FF;
  --palette-static-clouds-openstack: #ED1944FF;
  --palette-static-clouds-azure: #0089D6FF;
  --palette-static-clouds-google-green: #0F9D58FF;
  --palette-semantic-informative: #0971D7FF;
  --palette-semantic-positive: #38831BFF;
  --palette-semantic-notice: #976907FF;
  --palette-semantic-negative: #D42E0CFF;
  --palette-non-semantic-a: #A648B7FF;
  --palette-non-semantic-b: #DE2B5BFF;
  --palette-non-semantic-c: #D6421AFF;
  --palette-non-semantic-d: #F1C555FF;
  --palette-non-semantic-e: #A5DC65FF;
  --palette-non-semantic-f: #4AE85FFF;
  --palette-non-semantic-g: #58DFDFFF;
  --palette-non-semantic-h: #475DD1FF;
  --palette-gradients-accent: linear-gradient(180deg, #22154D 0%, #37227B 100%);
  --tones-main-accent-50: #F2EEFEFF;
  --tones-main-accent-100: #E8E2FEFF;
  --tones-main-accent-150: #D8CDFDFF;
  --tones-main-accent-200: #CBBCFCFF;
  --tones-main-accent-400: #9576F9FF;
  --tones-main-accent-500: #6F46F6FF;
  --tones-main-accent-600: #5836C9FF;
  --tones-main-accent-700: #482DA4FF;
  --tones-main-accent-800: #37227BFF;
  --tones-main-accent-900: #22154DFF;
  --tones-main-accent-950: #180F36FF;
  --tones-main-accent-1000: #0E081FFF;
  --tones-main-primary-25: #FAFAFAFF;
  --tones-main-primary-50: #F1F1F3FF;
  --tones-main-primary-75: #ECECEFFF;
  --tones-main-primary-100: #E3E4E8FF;
  --tones-main-primary-200: #D5D6DCFF;
  --tones-main-primary-300: #B9BBC5FF;
  --tones-main-primary-400: #9EA0AEFF;
  --tones-main-primary-500: #828596FF;
  --tones-main-primary-600: #696C7DFF;
  --tones-main-primary-700: #515361FF;
  --tones-main-primary-800: #3A3C45FF;
  --tones-main-primary-850: #2E3038FF;
  --tones-main-primary-900: #1C1D21FF;
  --tones-main-primary-1000: #101013FF;
  --tones-semantic-informative-50: #E6F2FEFF;
  --tones-semantic-informative-100: #BADBFCFF;
  --tones-semantic-informative-200: #8AC2FAFF;
  --tones-semantic-informative-300: #59A9F8FF;
  --tones-semantic-informative-400: #1485F5FF;
  --tones-semantic-informative-500: #0971D7FF;
  --tones-semantic-informative-600: #0861BAFF;
  --tones-semantic-informative-700: #064F98FF;
  --tones-semantic-informative-800: #05407AFF;
  --tones-semantic-informative-900: #042E58FF;
  --tones-semantic-informative-1000: #02172CFF;
  --tones-semantic-positive-50: #E4F8DDFF;
  --tones-semantic-positive-100: #D1F3C4FF;
  --tones-semantic-positive-200: #A9E891FF;
  --tones-semantic-positive-300: #7ADC56FF;
  --tones-semantic-positive-400: #54C729FF;
  --tones-semantic-positive-500: #46A522FF;
  --tones-semantic-positive-600: #38831BFF;
  --tones-semantic-positive-700: #2A6114FF;
  --tones-semantic-positive-800: #1F480FFF;
  --tones-semantic-positive-900: #16330BFF;
  --tones-semantic-positive-1000: #0D1E06FF;
  --tones-semantic-notice-50: #FDF4DDFF;
  --tones-semantic-notice-100: #FCEDC9FF;
  --tones-semantic-notice-200: #FADC98FF;
  --tones-semantic-notice-300: #F8CC6DFF;
  --tones-semantic-notice-400: #F6BA37FF;
  --tones-semantic-notice-500: #EFA70BFF;
  --tones-semantic-notice-600: #BE8509FF;
  --tones-semantic-notice-700: #976907FF;
  --tones-semantic-notice-800: #704E05FF;
  --tones-semantic-notice-900: #493303FF;
  --tones-semantic-notice-1000: #221801FF;
  --tones-semantic-negative-50: #FEEBE7FF;
  --tones-semantic-negative-100: #FCD6CFFF;
  --tones-semantic-negative-200: #FBBEB2FF;
  --tones-semantic-negative-300: #F78A73FF;
  --tones-semantic-negative-400: #F5684CFF;
  --tones-semantic-negative-500: #F24927FF;
  --tones-semantic-negative-600: #D42F0DFF;
  --tones-semantic-negative-700: #9F2209FF;
  --tones-semantic-negative-800: #791A07FF;
  --tones-semantic-negative-900: #481004FF;
  --tones-semantic-negative-1000: #220702FF;
  --tones-non-semantic-a-50: #F6EDF8FF;
  --tones-non-semantic-a-100: #EDDBF0FF;
  --tones-non-semantic-a-500: #A648B7FF;
  --tones-non-semantic-a-800: #53245BFF;
  --tones-non-semantic-b-50: #FCE9EEFF;
  --tones-non-semantic-b-100: #F9D7E0FF;
  --tones-non-semantic-b-500: #DE2B5BFF;
  --tones-non-semantic-b-800: #6F112AFF;
  --tones-non-semantic-c-50: #FCECE8FF;
  --tones-non-semantic-c-100: #FBE2DBFF;
  --tones-non-semantic-c-500: #D6421AFF;
  --tones-non-semantic-c-800: #72230EFF;
  --tones-non-semantic-d-50: #FDF7E7FF;
  --tones-non-semantic-d-100: #FCF1D4FF;
  --tones-non-semantic-d-500: #F1C555FF;
  --tones-non-semantic-d-800: #6D5109FF;
  --tones-non-semantic-e-50: #F3FAEAFF;
  --tones-non-semantic-e-100: #E9F7DAFF;
  --tones-non-semantic-e-500: #A5DC65FF;
  --tones-non-semantic-e-800: #3A5B15FF;
  --tones-non-semantic-f-50: #E8FCEBFF;
  --tones-non-semantic-f-100: #D2F9D7FF;
  --tones-non-semantic-f-500: #4AE85FFF;
  --tones-non-semantic-f-800: #0D6318FF;
  --tones-non-semantic-g-50: #E9FBFBFF;
  --tones-non-semantic-g-100: #CCF5F5FF;
  --tones-non-semantic-g-500: #58DFDFFF;
  --tones-non-semantic-g-800: #0C4040FF;
  --tones-non-semantic-h-50: #EFF1FBFF;
  --tones-non-semantic-h-100: #DBDFF6FF;
  --tones-non-semantic-h-500: #475DD1FF;
  --tones-non-semantic-h-800: #1A2666FF;
  --alpha-main-accent-500-10: #7D58F71A;
  --alpha-main-accent-500-30: #7D58F74D;
  --alpha-main-accent-500-50: #7D58F780;
  --alpha-main-accent-500-70: #7D58F7B3;
  --alpha-main-accent-500-90: #7D58F7E6;
  --alpha-main-primary-700-8: #51536114;
  --alpha-main-primary-700-10: #5153611A;
  --alpha-main-primary-700-30: #5153614D;
  --alpha-main-primary-700-50: #51536180;
  --alpha-main-primary-700-70: #515361B3;
  --alpha-main-primary-700-90: #515361E6;
  --alpha-main-primary-500-8: #82859614;
  --alpha-static-white-10: #FFFFFF1A;
  --alpha-static-white-30: #FFFFFF4D;
  --alpha-static-white-50: #FFFFFF80;
  --alpha-static-white-70: #FFFFFFB3;
  --alpha-static-white-90: #FFFFFFE6;
  --alpha-static-black-10: #1011191A;
  --alpha-static-black-30: #1011194D;
  --alpha-static-black-50: #10111980;
  --alpha-static-black-70: #101119B3;
  --alpha-static-black-90: #101119E6;
  --alpha-semantic-informative-08: #0971D714;
  --alpha-semantic-informative-10: #0971D71A;
  --alpha-semantic-informative-30: #0971D74D;
  --alpha-semantic-informative-50: #0971D780;
  --alpha-semantic-informative-70: #0971D7B3;
  --alpha-semantic-informative-90: #0971D7E6;
  --alpha-semantic-positive-08: #38831B14;
  --alpha-semantic-positive-10: #38831B1A;
  --alpha-semantic-positive-30: #38831B4D;
  --alpha-semantic-positive-50: #38831B80;
  --alpha-semantic-positive-70: #38831BB3;
  --alpha-semantic-positive-90: #38831BE6;
  --alpha-semantic-notice-08: #97690714;
  --alpha-semantic-notice-10: #9769071A;
  --alpha-semantic-notice-30: #9769074D;
  --alpha-semantic-notice-50: #97690780;
  --alpha-semantic-notice-70: #976907B3;
  --alpha-semantic-notice-90: #976907E6;
  --alpha-semantic-negative-08: #D42E0C14;
  --alpha-semantic-negative-10: #D42E0C1A;
  --alpha-semantic-negative-30: #D42E0C4D;
  --alpha-semantic-negative-50: #D42E0C80;
  --alpha-semantic-negative-70: #D42E0CB3;
  --alpha-semantic-negative-90: #D42E0CE6;
  --alpha-shadows-subtle: #82859614;
  --alpha-shadows-moderate: #51536114;
  --alpha-shadows-negative: #D42E0C14;
  --alpha-shadows-notice: #97690714;
  --alpha-shadows-positive: #38831B14;
  --alpha-shadows-informative: #0971D714;
}
