@use 'sass:map';

$font-family: 'Inter', sans-serif !default;
$font-family-monospace: 'Roboto Mono', monospace !default;
$font-size: 15px;
$font-size-sm: 13px;
$font-size-xs: 11px;
$font-size-mono: 14px;

$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;

$heading-line-height: 1.2 !default;
$heading-spacing-top: 0 !default;
$heading-spacing-bottom: 0 !default;
$heading-weight: $font-weight-medium !default;
$heading-sizes: () !default;
$heading-sizes: map.merge((
  'small': (
    h1: 2.5rem,
    h2: 2rem,
    h3: 1.7rem,
    h4: 1.4rem,
    h5: 1.2rem,
    h6: 1rem
  ),
  'large': (
    h1: 2.5rem,
    h2: 2rem,
    h3: 1.5rem,
    h4: 1.35rem,
    h5: 1.25rem,
    h6: 1.15rem
  )
), $heading-sizes);

$font-sizes: () !default;
$font-sizes: map.merge((
  small: .75rem,
  caption: .8125rem,
  annotation: 1.2rem,
  input: .875rem,
  medium: 1.1rem,
  large: 1.3rem,
  xlarge: 1.7rem,
  xxlarge: 2rem,
), $font-sizes);

$paragraph-line-height: 1.4 !default;
$paragraph-spacing-top: 0 !default;
$paragraph-spacing-bottom: 0 !default;
$paragraph-weight: $font-weight-normal !default;
$paragraph-sizes: (
  'small': .875rem,
) !default;
