@use '../tools/breakpoint.mixin' as bp;
@use '../settings/typo-setting' as typography;

.rule-info {
  p {
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;

    @include bp.breakpoint(medium) {
      flex-direction: row;
    }

    .rule-key {
      font-size: typography.$font-size-mono;
      font-family: typography.$font-family-monospace;
      font-weight: typography.$font-weight-bold;
      display: block;
      flex: 0 0 168px;
    }

    .rule-value {
      font-size: typography.$font-size-mono;
      font-family: typography.$font-family-monospace;
      line-height: 18px;

      a {
        font-size: typography.$font-size-mono;
        font-family: typography.$font-family-monospace;
        color: var(--tones-semantic-informative-500);

        &:hover {
          text-decoration: underline;
        }
      }

      &.highlight {
        &:not(:empty) {
          padding: 0 2px;
          border-radius: 3px;
          border: 1px solid;

          &.highlight--default {
            border-color: var(--tones-main-primary-200);
            background-color: var(--palette-static-white);
          }

          &.highlight--info {
            color: var(--tones-semantic-informative-800);
            border-color: var(--tones-semantic-informative-500);
            background-color: var(--tones-semantic-informative-100);
          }

          &.highlight--success {
            color: var(--tones-semantic-positive-800);
            border-color: var(--tones-semantic-positive-600);
            background-color: var(--tones-semantic-positive-100);
          }

          &.highlight--warning {
            color: var(--tones-semantic-notice-800);
            border-color: var(--tones-semantic-notice-600);
            background-color: var(--tones-semantic-notice-100);
          }

          &.highlight--error {
            color: var(--tones-semantic-negative-800);
            border-color: var(--tones-semantic-negative-500);
            background-color: var(--tones-semantic-negative-100);
          }

          &.highlight--accent {
            color: var(--tones-main-accent-800);
            border-color: var(--tones-main-accent-500);
            background-color: var(--tones-main-accent-100);
          }

          &.highlight--secondary,
          &.highlight--primary {
            color: var(--tones-main-primary-800);
            border-color: var(--tones-main-primary-800);
            background-color: var(--tones-main-primary-100);
          }

          &.highlight--solid--error,
          &.highlight--solid--firing,
          &.highlight--solid--danger {
            color: var(--palette-static-white);
            border: none;
            background-color: var(--tones-semantic-negative-500);
          }

          &.highlight--solid--pending,
          &.highlight--solid--warning {
            color: var(--palette-static-white);
            border: none;
            background-color: var(--tones-semantic-notice-600);
          }

          &.highlight--solid--inactive,
          &.highlight--solid--success {
            color: var(--palette-static-white);
            border: none;
            background-color: var(--tones-semantic-positive-600);
          }
        }
      }

      &.string {
        &:not(:empty) {
          &.string--silence-reason {
            color: var(--tones-semantic-negative-500);
          }
        }
      }
    }
  }
}

.rule-container {
  padding: 8px 16px;
  border-radius: 5px;
  background-color: var(--tones-main-primary-50);
}
