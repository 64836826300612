.key-value-pair {
  padding-bottom: 5px;
  font-size: 12px;
  white-space: nowrap;

  &:last-child {
    padding-bottom: 0;
  }

  .key {
    font-weight: 700;
    margin-right: 5px;
  }
}
