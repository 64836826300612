@use 'sass:map';
@use '../../scss/settings/typo-setting' as typoset;

.chunk-container {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: .75rem 1rem;
  border-radius: var(--global-radius);
  background-color: var(--palette-static-white);
  box-shadow: var(--shadow-default-2);
}

.chunk-item {
  display: grid;
  padding: 0 1rem 0 0;
  font-size: map.get(typoset.$font-sizes, caption);

  &:not(:last-child) {
    border-right: 1px solid var(--tones-main-primary-200);
  }

  :not(.chunk-heading) {
    font-weight: typoset.$font-weight-medium;
  }
}

.chunk-heading {
  padding-bottom: .5rem;
  font-size: map.get(typoset.$font-sizes, small);
  color: var(--tones-main-primary-600);
}
