.white-space {
  &--nowrap {
    white-space: nowrap;
  }

  &--normal {
    white-space: normal;
  }

  &--break-spaces {
    white-space: break-spaces;
  }
}
