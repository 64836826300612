.animate {
  &--spin {
    animation: spin .8s linear infinite;
  }

  &--spin--ease {
    animation: spin .8s ease-in-out infinite;
  }

  &--wiggle {
    animation: wiggle 3s ease infinite;
  }

  &--highlight-button {
    animation: wiggle 3s ease 20;
  }
}

.animation-delay {
  @for $i from 1 to 6 {
    &--#{$i} {
      animation-delay: $i * 1s;
    }
  }
}
