@use '../tools/media-breakpoints' as bp;

.page-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  &.bottom {
    margin-bottom: 0;
  }

  &.right {
    justify-content: flex-end;
  }

  &--primary-50 {
    background-color: var(--tones-main-primary-50);
  }



  @include bp.screen('l') {
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 0;
  }
}

.selection-actions {
  display: flex;
  align-items: center;
  padding-right: 15px;

  @include bp.screen('l') {
    padding-right: 0;
    padding-left: 10px;
    margin-top: 15px;
    align-self: flex-start;
  }
}

.selection-actions-title {
  margin: 0 .5rem 0 0;
  font-size: 13px;

  span {
    font-weight: 700;
    font-size: 15px;
  }
}

.common-actions {
  display: flex;
  border-left: 1px solid var(--tones-main-primary-100);
  margin-left: auto;

  .common-actions--separated {
    padding-left: 10px;
    padding-right: 10px;
    border-right: 1px solid var(--tones-main-primary-100);
  }

  @include bp.screen('l') {
    margin-left: 0;
    margin-bottom: 10px;
    border-left: none;
    padding-left: 0;
  }
}

.common-server-actions {
  display: flex;
  gap: .5rem;

  .common-server-actions-separator {
    display: inline-block;
    width: 1px;
    background-color: var(--tones-main-primary-200);
  }
}
