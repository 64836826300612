@keyframes wiggle {
  0% {
    transform: rotateZ(0);
  }
  3% {
    transform: rotateZ(-15deg);
  }
  6% {
    transform: rotateZ(10deg);
  }
  9% {
    transform: rotateZ(-8deg);
  }
  12% {
    transform: rotateZ(6deg);
  }
  15% {
    transform: rotateZ(-4deg);
  }
  18%, 100% {
    transform: rotateZ(0);
  }
}
