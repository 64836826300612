@use 'sass:map';
@use '../tools/breakpoint.mixin' as bp;
@use '../settings/breakpoint-setting' as bpset;

$gap-level: 10 !default;

.display {
  &--flex {
    display: flex;
  }

  &--inline-flex {
    display: inline-flex;
  }

  &--block {
    display: block;
  }

  &--none {
    display: none;
  }
}

.flex-direction {
  &--row {
    flex-direction: row;
  }

  &--row-reverse {
    flex-direction: row-reverse;
  }

  &--column {
    flex-direction: column;
  }

  &--column-reverse {
    flex-direction: column-reverse;
  }
}

.gap {
  &--2 {
    gap: 2px;
  }

  &--4 {
    gap: 4px;
  }

  @for $i from 1 to ($gap-level + 1) {
    $a: $i * 8;

    &--#{$a} {
      gap: $a * 1px;
    }
  }
}

.flex-child {
  flex: 1 1 0;

  &--full {
    flex: 1 1 100%;
  }

  &--auto {
    flex: 1 1 auto;
  }

  &--zero {
    flex: 1 1 0;
  }

  &--auto--zero {
    flex: 0 0 auto;
  }
}

.flex-wrap {
  &--nowrap {
    flex-wrap: nowrap;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}


@each $bp, $bpvalue in bpset.$breakpoints {
  @include bp.breakpoint($bp) {
    .flex-direction {
      &--row--#{$bp} {
        flex-direction: row;
      }

      &--row-reverse--#{$bp} {
        flex-direction: row-reverse;
      }

      &--column--#{$bp} {
        flex-direction: column;
      }

      &--column-reverse--#{$bp} {
        flex-direction: column-reverse;
      }
    }

    .flex-child {
      flex: 1 1 0;

      &--full--#{$bp} {
        flex: 1 1 100%;
      }

      &--auto--#{$bp} {
        flex: 1 1 auto;
      }

      &--zero--#{$bp} {
        flex: 1 1 0;
      }

      &--auto--zero--#{$bp} {
        flex: 0 0 auto;
      }
    }
  }
}
