@use '../tools/breakpoint.mixin' as bp;

.main-nav-toggle {
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  width: 2rem;
  height: 2rem;
  font-size: 14px;
  color: var(--tones-main-primary-800);
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: color var(--global-easing-time) var(--global-easing);

  &:hover {
    color: var(--tones-main-accent-500);
  }

  &.main-nav-toggle--desktop {
    display: none;

    @include bp.breakpoint('large') {
      display: flex;
    }
  }

  &.main-nav-toggle--mobile {
    display: flex;

    @include bp.breakpoint('large') {
      display: none;
    }
  }
}
