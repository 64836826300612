@use 'sass:map';
@use '../tools/breakpoint.mixin' as bp;
@use '../settings/breakpoint-setting' as bpset;

$sizes: () !default;
$sizes: map.merge((
  '25': '25%',
  '50': '50%',
  '75': '75%',
  '100': '100%',
  '20px' : '20px',
  '100px': '100px',
  '200px': '200px',
  '300px': '300px',
  '400px': '400px',
  '500px': '500px',
), $sizes);

@each $size, $value in $sizes {
  .width {
    &--#{$size} {
      width: unquote($value) !important;
    }

    &--auto {
      width: auto !important;
    }
  }

  .max-width {
    &--#{$size} {
      max-width: unquote($value) !important;
    }

    &--unset {
      max-width: unset !important;
    }
  }

  .min-width {
    &--#{$size} {
      min-width: unquote($value) !important;
    }

    &--auto {
      min-width: auto !important;
    }
  }

  @each $bp, $bpvalue in bpset.$breakpoints {
    @include bp.breakpoint($bp) {
      .width {
        &--#{$size}--#{$bp} {
          width: unquote($value) !important;
        }

        &--auto--#{$bp} {
          width: auto !important;
        }
      }

      .max-width{
        &--#{$size}--#{$bp} {
          max-width: unquote($value) !important;
        }

        &--unset--#{$bp} {
          max-width: unset !important;
        }
      }

      .min-width {
        &--#{$size}--#{$bp} {
          min-width: unquote($value) !important;
        }

        &--auto--#{$bp} {
          min-width: auto !important;
        }
      }
    }
  }
}

.full-width {
  width: 100%;
}
