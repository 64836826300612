@use 'sass:map';
@use '../settings/typo-setting' as typography;

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  label {
    margin-left: 2px;
    color: var(--tones-main-primary-800);
  }

  &.is-optional {
    label {
      display: flex;
      justify-content: space-between;
      align-items: end;

      &::after {
        content: ' (optional)';
        margin-left: 0.5rem;
        font-size: map.get(typography.$font-sizes, small);
      }
    }
  }
}

.form-field {
  input,
  mat-select {
    width: 100%;
    margin: 0;
  }
}
