.online-indicator {
  --size: 8px;

  display: inline-block;
  margin: 1px 6px;
  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  background-color: var(--tones-semantic-positive-600);

  &:before {
    content: '';
    display: block;
    z-index: -1;
    width: var(--size);
    height: var(--size);
    border-radius: var(--size);
    background-color: var(--tones-semantic-positive-600);
    animation: onlineIndicator 1.3s infinite ease-out;
  }

  @keyframes onlineIndicator {
    0% {
      transform: scale3d(1, 1, 1);
      opacity: .7;
    }
    100% {
      transform: scale3d(3, 3, 3);
      opacity: 0;
    }
  }
}
