.overflow {
  &--hidden {
    overflow: hidden !important;
  }

  &--auto {
    overflow: auto !important;
  }

  &--y {
    overflow-y: auto !important;
  }

  &--x {
    overflow-x: auto !important;
  }
}
