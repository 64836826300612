@use 'sass:list';

$max-width: 3 !default;

$border-colors: () !default;
$border-colors: list.join((
  accent,
  primary,
  border,
), $border-colors);


$directions: (
  top,
  right,
  bottom,
  left
);

.border {
  &-style {
    &--solid {
      border-style: solid;
    }

    @each $dir in $directions {
      &--#{$dir}--solid {
        border-#{$dir}-style: solid;
      }
    }
  }

  &-width {
    @for $i from 0 to $max-width {
      &--#{$i} {
        border-width: $i * 1px;
      }

      @each $dir in $directions {
        &--#{$dir}--#{$i} {
          border-#{$dir}-width: $i * 1px;
        }
      }
    }
  }

  &-color {
    @each $color in $border-colors {
      &--#{$color} {
        border-color: var(--color-#{$color});
      }
    }
  }
}
