@use './mixins' as mixins;

h1 {
  @include mixins.h1;

  &._size-sm {
    @include mixins.heading-size(--heading-size-xxl, small);
  }
}
h2 {
  @include mixins.h2;

  &._size-sm {
    @include mixins.heading-size(--heading-size-xl, small);
  }
}

h3 {
  @include mixins.h3;

  &._size-sm {
    @include mixins.heading-size(--heading-size-l, small);
  }
}

h4 {
  @include mixins.h4;

  &._size-sm {
    @include mixins.heading-size(--heading-size-m, small);
  }
}

h5 {
  @include mixins.h5;

  &._size-sm {
    @include mixins.heading-size(--heading-size-s, small);
  }
}

h6 {
  @include mixins.h6;

  &._size-sm {
    @include mixins.heading-size(--heading-size-xs, small);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @each $key, $transform in mixins.$heading-transform {
    &._transform-#{$key} {
      @include mixins.heading-transform($key);
    }
  }
}

body {
  @include mixins.font-base;
}

a {
  &:not(.button, .toolbar-button, .itera-link-button, .tk-link-button) {
    color: var(--text-color, var(--palette-static-black));
  }
}
