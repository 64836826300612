@use '../settings/typo-setting' as typography;

.monitoring-row {
  margin-bottom: 1.5rem;
  border-radius: 8px;
  border: 1px solid var(--tones-main-primary-100);
  background-color: var(--palette-static-white);
}

.monitoring-row__heading {
  display: flex;
  gap: 1rem;
  padding: 1rem;
}

.monitoring-row-value {
  display: flex;
  flex: 1 1 100%;
  cursor: pointer;
  gap: 1rem;

  .monitoring-row-value__icon {
    flex: 0 0 auto;
  }

  .monitoring-row-value__body {
    flex: 1 1 100%;

    .monitoring-row-value__body__description {
      margin-top: .5rem;
    }

    .monitoring-row-value__body__start-time {
      font-size: typography.$font-size-sm;
      color: var(--tones-main-primary-400);
      margin-top: 5px;
    }
  }
}

.monitoring-row__silence-status {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  .monitoring-silence-status__actions {
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    .monitoring-alert-mute-status {
      margin-top: 6px;
      color: var(--tones-semantic-negative-500);

      &.is-silenced {
        color: var(--tones-main-primary-300);
      }
    }
  }

  .monitoring-silence-status__log {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: .5rem;
    font-size: typography.$font-size-sm;
    color: var(--tones-main-primary-400);
    text-align: right;
  }
}
