.line-clamp {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;

  @for $a from 1 to 6 {
    &--#{$a} {
      -webkit-line-clamp: $a;
      line-clamp: $a;
    }
  }
}
