@use '../tools/breakpoint.mixin' as bp;
@use '../settings/typo-setting' as typography;

.page-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 1rem;

  .page-header-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    @include bp.breakpoint(large) {
      h1 {
        white-space: nowrap;
      }
    }
  }

  .page-header-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .page-header-text {
    font-size: 21px;

    .page-header-text-value {
      padding-left: 4px;
      font-weight: typography.$font-weight-bold;
      color: var(--tones-main-primary-800);
    }
  }

  @include bp.breakpoint(large) {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
}
