@use '../tools/breakpoint.mixin' as bp;

.filter-bar-container.servers,
.filter-bar-container.flavors,
.filter-bar-container.cloud-credentials {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;

  app-range {
    flex: 1 1 100%;
    width: 100%;
    margin: 0;

    @include bp.breakpoint(medium) {
      flex-basis: auto;
      width: auto;
      margin: 0 20px;
    }
  }
}

.filter-bar-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  background-color: var(--palette-static-white);
  border: 1px solid var(--color-border);
  border-radius: 5px;

  @include bp.breakpoint(xlarge) {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }

  .filter-bar-left {
    flex: 1 1 auto;
    padding: .5rem;
  }

  .filter-bar-right {
    flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    padding: .5rem;

    .filter-select {
      flex: 1 1 100%;

      .mat-select {
        width: 100% !important;
      }
    }

    @include bp.breakpoint(medium) {
      flex-wrap: nowrap;

      .filter-select {
        flex: 1 1 0;
      }
    }
  }
}

.filter-failed {
  margin-right: 0;
  margin-bottom: 20px;

  @include bp.breakpoint(medium) {
    margin-right: 30px;
    margin-bottom: 0;
  }
}

.only-failed-row {
  padding-top: 10px;
  padding-left: 10px;
}
