@import './foundations';

:root {
  --heading-size-xs: var(--font-size-300);
  --heading-size-s: var(--font-size-400);
  --heading-size-m: var(--font-size-500);
  --heading-size-l: var(--font-size-600);
  --heading-size-xl: var(--font-size-700);
  --heading-size-xxl: var(--font-size-800);
  --heading-transform: var(--text-transform-titlecase);
  --heading-line-height: var(--line-height-130);
  --heading-weight-normal: var(--font-weight-600);

  --body-size: var(--font-size-100);
  --body-weight-normal: var(--font-weight-400);
  --body-weight-strong: var(--font-weight-600);
  --body-transform: none;
  --body-line-height: var(--line-height-150);

  --caption--size: var(--font-size-75);
  --caption-weight-normal: var(--font-weight-500);
  --caption-weight-strong: var(--font-weight-600);
  --caption-transform: var(--text-transform-titlecase);
  --caption-line-height: var(--line-height-130);

  --helper-size: var(--font-size-50);
  --helper-weight-normal: var(--font-weight-500);
  --helper-weight-strong: var(--font-weight-700);
  --helper-transform: none;
  --helper-line-height: var(--line-height-150);

  --highlight-size: var(--font-size-300);
  --highlight-weight-normal: var(--font-weight-400);
  --highlight-weight-strong: var(--font-weight-600);
  --highlight-transform: none;
  --highlight-line-height: var(--line-height-150);

  --code-size-xs: var(--font-size-50);
  --code-size-s: var(--font-size-75);
  --code-size-m: var(--font-size-100);
  --code-weight-normal: var(--font-weight-500);
  --code-weight-strong: var(--font-weight-700);
  --code-transform: none;
  --code-line-height: var(--line-height-150);

  --label-size-m: var(--font-size-100);
  --label-size-s: var(--font-size-75);
  --label-weight-normal: var(--font-weight-500);
  --label-weight-strong: var(--font-weight-600);
  --label-transform: var(--text-transform-titlecase);
  --label-line-height: var(--line-height-130);

  --prominents-size: var(--font-size-600);
  --prominent-weight-normal: var(--font-weight-400);
  --prominent-weight-strong: var(--font-weight-600);
  --prominent-line-height: var(--line-height-150);
  --prominent-transform: none;
}
